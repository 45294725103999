import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import tw from "twin.macro";
import "./Slider.css";

const Carousel = ({ images }) => {
  const ArrowBase = styled.div`
    ${tw`absolute top-1/2 transform -translate-y-1/2 cursor-pointer`}
    width: 30px;
    height: 30px;
    font-size: 30px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  `;

  const PrevArrow = (props) => (
    <ArrowBase {...props} style={{ left: "10px" }}></ArrowBase>
  );

  const NextArrow = (props) => (
    <ArrowBase {...props} style={{ right: "10px" }}></ArrowBase>
  );

  const settings = {
    dots: true,
    dotsClass: "slick-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: "linear",
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <PrevArrow />, // Custom component for the previous arrow
    nextArrow: <NextArrow />, // Custom component for the next arrow
  };

  const StyledSlider = styled(Slider)`
    ${tw`mt-16`}
    .slick-track {
      ${tw`flex`}
    }
    .slick-slide {
      ${tw`h-auto flex justify-center mb-0 lg:h-auto md:h-auto sm:h-auto`}
    }
  `;

  return (
    <StyledSlider {...settings}>
      {images.map((image, index) => (
        <div key={index} className="carousel-item">
          <img src={image} alt={`Slide ${index + 1}`} />
        </div>
      ))}
    </StyledSlider>
  );
};

export default Carousel;
