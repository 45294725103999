import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import aboutText from "./Text/Text";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "shared/Headings.js";
import Header from "components/App/Header/Header";
import Footer from "components/App/Footer/Footer";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import AboutImg from "../../images/About.png";

const Container = tw.div`relative py-8 lg:p-8  mb-6`;
const Content = tw.div`flex flex-col max-w-screen-xl mx-auto md:pt-16`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`mt-10 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-8 md:order-first`
    : tw`md:ml-12 lg:ml-8 md:order-last`,
]);

const TextContent = tw.div`mt-10 md:text-left xs:mb-10`;
const ImageContainer = styled.div`
  ${tw`h-100 w-100 mt-10 lg:mx-[8rem] sm:w-full`}
`;
const Heading = tw(
  SectionHeading
)`mt-5 text-secondary-1000 md:text-left text-3xl sm:text-4xl lg:text-5xl leading-tight`;
const Description = tw.p`mt-4 w-full font-medium lg:text-sm md:text-base leading-relaxed text-secondary-100`;

const Subheading = tw(SectionHeading)`md:text-left mt-10`;
const Row = tw.div`flex flex-col items-center justify-center md:flex-row-reverse md:justify-between`;
const Cards = tw.div`flex flex-wrap max-w-screen-xl justify-center md:justify-start`;
const Card = tw.div`mt-10 flex flex-col items-center justify-center md:items-start md:justify-start`;
const CardImage = styled.div`
  ${(props) =>
    props.imageSrc
      ? `background-image: url("${props.imageSrc}");`
      : `background-color: #f0f0f0;`}
  ${tw`w-40 h-40 bg-cover bg-center rounded`}
`;
const CardContent = tw.div`flex flex-col items-center mt-4 md:items-start`;
const Position = tw.span`uppercase font-bold tracking-widest text-xs text-secondary-1000 mt-2 md:mt-0`;
const Name = tw.span`w-64 text-sm font-medium text-gray-900 uppercase text-center md:text-left`;

export default ({ imageInsideDiv = true, textOnLeft = false }) => {
  const cards = [
    {
      imageSrc: require("../../images/waqas-pic.jpg"),
      position: "Product",
      name: "Waqas Ahmed",
    },

    {
      imageSrc: require("../../images/awais-pic.jpg"),
      position: "Graphic Designer",
      name: "Awais Amjad",
    },
    // {
    //   imageSrc: require("../../images/owais-pic.jpg"),
    //   position: "Tech",
    //   name: "Muhammad Owais",
    // },
    {
      imageSrc: require("../../images/mehak-pic.jpg"),
      position: "Partnerships",
      name: "Mehak Rehman",
    },

    {
      imageSrc: require("../../images/khuram-pic.jpg"),
      position: "Lead",
      name: "Khurram Abdul Maalik",
    },

    {
      imageSrc: require("../../images/naba-pic.jpg"),
      position: "Tech",
      name: "Naba Zehra",
    },
  ];

  return (
    <>
      <AnimationRevealPage disabled>
        <Header title="About" />

        <Container>
          <Content className="" css={!imageInsideDiv && tw`md:items-center`}>
            <Row>
              <TextColumn textOnLeft={textOnLeft}>
                <TextContent>
                  <Heading>
                    <span>About</span>
                  </Heading>

                  <Description>
                    <div dangerouslySetInnerHTML={{ __html: aboutText }} />
                  </Description>
                </TextContent>
              </TextColumn>
              <Column>
                <ImageContainer>
                  <img src={AboutImg} alt="{About}" />
                </ImageContainer>
              </Column>
            </Row>
            <TextColumn>
              <Subheading>Our Team</Subheading>

              <Cards>
                {cards.map((card, index) => (
                  <Card key={index}>
                    <CardImage imageSrc={card.imageSrc} />
                    <CardContent>
                      <Position>{card.position}</Position>
                      <Name>{card.name}</Name>
                    </CardContent>
                  </Card>
                ))}
              </Cards>
            </TextColumn>
          </Content>
        </Container>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
